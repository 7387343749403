/* This File is for Error pages */

<template>
  <div>Error {{ $route.params.code }}</div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped></style>
